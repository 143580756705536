// List of icons: https://vue-feather-icons.egoist.sh/
import { FilterIcon, RadioIcon, PaperclipIcon, TypeIcon, CopyIcon, ChevronDownIcon, ChevronUpIcon, InfoIcon, ClipboardIcon, AlertTriangleIcon, AlertCircleIcon, MonitorIcon, BellIcon, SettingsIcon, MenuIcon, UserIcon, ArrowLeftIcon, ArrowUpIcon, ArrowDownIcon, Link2Icon, ChevronRightIcon, TagIcon, LayersIcon, GitPullRequestIcon, ChevronsRightIcon, BarChart2Icon, CheckIcon, FileTextIcon, DownloadIcon, XCircleIcon, CheckCircleIcon, LogInIcon, LogOutIcon, XIcon, Trash2Icon, CheckSquareIcon, CommandIcon, PrinterIcon, AlertOctagonIcon, LockIcon, Edit3Icon, MoreHorizontalIcon, GridIcon, CpuIcon, EditIcon, CalendarIcon, SearchIcon, SlidersIcon, MapIcon, RepeatIcon, MapPinIcon, EyeIcon, EyeOffIcon, ArchiveIcon, PlusCircleIcon, FilePlusIcon, SunIcon, SaveIcon, ClockIcon, MaximizeIcon, MinimizeIcon, ArrowLeftCircleIcon, ArrowRightCircleIcon, HomeIcon, Minimize2Icon, Maximize2Icon, Volume2Icon, VolumeXIcon, ZoomInIcon, ZoomOutIcon, ImageIcon, ZapIcon, ZapOffIcon, ExternalLinkIcon, UserXIcon, HelpCircleIcon } from "vue-feather-icons";

function camelToKebab (name) {
	return name.substr(0, name.length - 4).replace(/([A-Z])/g, "-$1").toLowerCase();
}

function genIcon (name, children, flip = false) {
	return {
		name,
		props: {
			size: {
				type: String,
				default: "24",
				validator: function validator (s) {
					return !isNaN(s) || s.length >= 2 && !isNaN(s.slice(0, s.length - 1)) && s.slice(-1) === "x";
				}
			}
		},
		functional: true,
		render: function render (h, ctx) {
			const size = ctx.props.size.slice(-1) === "x" ? ctx.props.size.slice(0, ctx.props.size.length - 1) + "em" : parseInt(ctx.props.size) + "px";
			const attrs = ctx.data.attrs || {};
			attrs.width = attrs.width || size;
			attrs.height = attrs.height || size;
			ctx.data.attrs = attrs;
			return h("svg", {
				attrs: {
					xmlns: "http://www.w3.org/2000/svg",
					width: "24",
					height: "24",
					viewBox: "0 0 24 24",
					fill: flip ? "currentColor" : "none",
					stroke: flip ? "none" : "currentColor",
					"stroke-width": "2",
					"stroke-linecap": "round",
					"stroke-linejoin": "round"
				},
				class: `feather feather${camelToKebab(name)}`,
				...ctx.data
			}, children(h));
		}
	};
}

const DashboardIcon = genIcon("DashboardIcon", h => [h("path", {
	attrs: {
		d: "M12.984 3h8.016v6h-8.016v-6zM12.984 21v-9.984h8.016v9.984h-8.016zM3 21v-6h8.016v6h-8.016zM3 12.984v-9.984h8.016v9.984h-8.016z"

	}
})], true);

const LineChartIcon = genIcon("LineChartIcon", h => [h("path", {
	attrs: {
		d: "M22 11h-4c-0.4 0-0.8 0.3-0.9 0.7l-2.1 6.1-5.1-15.1c-0.1-0.4-0.5-0.7-0.9-0.7s-0.8 0.3-0.9 0.7l-2.8 8.3h-3.3c-0.6 0-1 0.4-1 1s0.4 1 1 1h4c0.4 0 0.8-0.3 0.9-0.7l2.1-6.1 5.1 15.2c0.1 0.4 0.5 0.7 0.9 0.7s0.8-0.3 0.9-0.7l2.8-8.3h3.3c0.6 0 1-0.4 1-1s-0.4-1.1-1-1.1z",
		fill: "currentColor"
	}
})]);

const BlurLinearIcon = genIcon("BlurLinearIcon", h => [h("path", {
	attrs: {
		d: "M12.984 17.016c-0.563 0-0.984-0.469-0.984-1.031s0.422-0.984 0.984-0.984 1.031 0.422 1.031 0.984-0.469 1.031-1.031 1.031zM12.984 12.984c-0.563 0-0.984-0.422-0.984-0.984s0.422-0.984 0.984-0.984 1.031 0.422 1.031 0.984-0.469 0.984-1.031 0.984zM12.984 9c-0.563 0-0.984-0.422-0.984-0.984s0.422-1.031 0.984-1.031 1.031 0.469 1.031 1.031-0.469 0.984-1.031 0.984zM17.016 12.516c-0.281 0-0.516-0.234-0.516-0.516s0.234-0.516 0.516-0.516 0.469 0.234 0.469 0.516-0.188 0.516-0.469 0.516zM17.016 8.484c-0.281 0-0.516-0.188-0.516-0.469s0.234-0.516 0.516-0.516 0.469 0.234 0.469 0.516-0.188 0.469-0.469 0.469zM3 3h18v2.016h-18v-2.016zM17.016 16.5c-0.281 0-0.516-0.234-0.516-0.516s0.234-0.469 0.516-0.469 0.469 0.188 0.469 0.469-0.188 0.516-0.469 0.516zM9 17.016c-0.563 0-0.984-0.469-0.984-1.031s0.422-0.984 0.984-0.984 0.984 0.422 0.984 0.984-0.422 1.031-0.984 1.031zM5.016 13.5c-0.844 0-1.5-0.656-1.5-1.5s0.656-1.5 1.5-1.5 1.5 0.656 1.5 1.5-0.656 1.5-1.5 1.5zM5.016 9.516c-0.844 0-1.5-0.656-1.5-1.5s0.656-1.5 1.5-1.5 1.5 0.656 1.5 1.5-0.656 1.5-1.5 1.5zM3 21v-2.016h18v2.016h-18zM9 9c-0.563 0-0.984-0.422-0.984-0.984s0.422-1.031 0.984-1.031 0.984 0.469 0.984 1.031-0.422 0.984-0.984 0.984zM9 12.984c-0.563 0-0.984-0.422-0.984-0.984s0.422-0.984 0.984-0.984 0.984 0.422 0.984 0.984-0.422 0.984-0.984 0.984zM5.016 17.484c-0.844 0-1.5-0.656-1.5-1.5s0.656-1.5 1.5-1.5 1.5 0.656 1.5 1.5-0.656 1.5-1.5 1.5z",
		fill: "currentColor"
	}
})]);

const OCheckNetworkIcon = genIcon("OCheckNetworkIcon", h => [h("path", {
	attrs: {
		d: "M14.25 18.6667C14.25 18.3793 14.1358 18.1038 13.9327 17.9006C13.7295 17.6975 13.4539 17.5833 13.1666 17.5833H12.0833V15.4167H16.4166C16.9913 15.4167 17.5424 15.1884 17.9487 14.7821C18.355 14.3757 18.5833 13.8246 18.5833 13.25V2.41667C18.5833 1.84203 18.355 1.29093 17.9487 0.884602C17.5424 0.478273 16.9913 0.25 16.4166 0.25H5.58329C5.00866 0.25 4.45756 0.478273 4.05123 0.884602C3.6449 1.29093 3.41663 1.84203 3.41663 2.41667V13.25C3.41663 13.8246 3.6449 14.3757 4.05123 14.7821C4.45756 15.1884 5.00866 15.4167 5.58329 15.4167H9.91663V17.5833H8.83329C8.54597 17.5833 8.27042 17.6975 8.06726 17.9006C7.8641 18.1038 7.74996 18.3793 7.74996 18.6667H0.166626V20.8333H7.74996C7.74996 21.1206 7.8641 21.3962 8.06726 21.5994C8.27042 21.8025 8.54597 21.9167 8.83329 21.9167H13.1666C13.4539 21.9167 13.7295 21.8025 13.9327 21.5994C14.1358 21.3962 14.25 21.1206 14.25 20.8333H21.8333V18.6667H14.25ZM5.58329 13.25V2.41667H16.4166V13.25H5.58329ZM6.66663 8.23417L8.00996 6.89083L9.84079 8.7325L13.99 4.58333L15.3333 6.20833L9.84079 11.7008L6.66663 8.23417Z",
		fill: "currentColor"
	}
})]);

const AppsIcon = genIcon("AppsIcon", h => [h("path", {
	attrs: {
		d: "M15.984 20.016v-4.031h4.031v4.031h-4.031zM15.984 14.016v-4.031h4.031v4.031h-4.031zM9.984 8.016v-4.031h4.031v4.031h-4.031zM15.984 3.984h4.031v4.031h-4.031v-4.031zM9.984 14.016v-4.031h4.031v4.031h-4.031zM3.984 14.016v-4.031h4.031v4.031h-4.031zM3.984 20.016v-4.031h4.031v4.031h-4.031zM9.984 20.016v-4.031h4.031v4.031h-4.031zM3.984 8.016v-4.031h4.031v4.031h-4.031z",
		fill: "currentColor"
	}
})]);

const BanIcon = genIcon("BanIcon", h => [h("path", {
	attrs: {
		d: "M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-8-8A7.92,7.92,0,0,1,5.69,7.1L16.9,18.31A7.92,7.92,0,0,1,12,20Zm6.31-3.1L7.1,5.69A7.92,7.92,0,0,1,12,4a8,8,0,0,1,8,8A7.92,7.92,0,0,1,18.31,16.9Z",
		fill: "currentColor"
	}

})]);

const QuestionIcon = genIcon("QuestionIcon", h => [h("path", {
	attrs: {
		d: "M16.8594854,20.7417567 C15.3870177,21.5619833 13.7245582,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,13.7245582 21.5619833,15.3870177 20.7417567,16.8594854 L21.0946904,21.0946904 L16.8594854,20.7417567 Z M16.3916038,18.6958341 L18.9053096,18.9053096 L18.6958341,16.3916038 L18.8621131,16.1149882 C19.6030308,14.8824253 20,13.4715357 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C13.4715357,20 14.8824253,19.6030308 16.1149882,18.8621131 L16.3916038,18.6958341 Z M12.0003283,16.9983464 C11.4478622,16.9983464 11,16.5506311 11,15.9983464 C11,15.4460616 11.4478622,14.9983464 12.0003283,14.9983464 C12.5527943,14.9983464 13.0006565,15.4460616 13.0006565,15.9983464 C13.0006565,16.5506311 12.5527943,16.9983464 12.0003283,16.9983464 Z M13,14 L11,14 L11,13 C11,12.2626932 11.3827392,11.7004784 11.9774877,11.2286498 C12.1564111,11.0867055 12.2101653,11.0510845 12.5339634,10.8458119 C12.8225361,10.6628706 13,10.3473166 13,10 C13,9.44771525 12.5522847,9 12,9 C11.4477153,9 11,9.44771525 11,10 L9,10 C9,8.34314575 10.3431458,7 12,7 C13.6568542,7 15,8.34314575 15,10 C15,11.0395627 14.4660508,11.988994 13.6048137,12.5349773 C13.3517279,12.6954217 13.3206582,12.7160104 13.2204897,12.7954765 C13.0562911,12.9257393 13,13.0084267 13,13 L13,14 Z",
		fill: "currentColor"
	}

})]);

/**
 * @param {import("vue").VueConstructor} Vue
 */
export function install (Vue) {
	if (install.installed) return;
	install.installed = true;

	const icons = [
		HelpCircleIcon,
		BellIcon,
		SettingsIcon,
		MenuIcon,
		UserIcon,
		ArrowLeftIcon,
		ArrowUpIcon,
		ArrowDownIcon,
		DashboardIcon,
		Link2Icon,
		ChevronRightIcon,
		TagIcon,
		LayersIcon,
		GitPullRequestIcon,
		ChevronsRightIcon,
		BarChart2Icon,
		CheckIcon,
		XIcon,
		FileTextIcon,
		DownloadIcon,
		XCircleIcon,
		CheckCircleIcon,
		LogInIcon,
		LogOutIcon,
		Trash2Icon,
		CheckSquareIcon,
		CommandIcon,
		PrinterIcon,
		AlertOctagonIcon,
		AlertCircleIcon,
		LockIcon,
		Edit3Icon,
		MoreHorizontalIcon,
		GridIcon,
		CpuIcon,
		EditIcon,
		CalendarIcon,
		LineChartIcon,
		BlurLinearIcon,
		OCheckNetworkIcon,
		AppsIcon,
		BanIcon,
		SearchIcon,
		SlidersIcon,
		MapIcon,
		RepeatIcon,
		MapPinIcon,
		EyeIcon,
		EyeOffIcon,
		ArchiveIcon,
		PlusCircleIcon,
		FilePlusIcon,
		SunIcon,
		SaveIcon,
		MonitorIcon,
		AlertTriangleIcon,
		ClipboardIcon,
		ClockIcon,
		InfoIcon,
		MaximizeIcon,
		MinimizeIcon,
		Maximize2Icon,
		Minimize2Icon,
		ArrowLeftCircleIcon,
		ArrowRightCircleIcon,
		CopyIcon,
		TypeIcon,
		HomeIcon,
		PaperclipIcon,
		Volume2Icon,
		VolumeXIcon,
		ZoomInIcon,
		ZoomOutIcon,
		ImageIcon,
		ZapIcon,
		ZapOffIcon,
		ExternalLinkIcon,
		ChevronDownIcon,
		ChevronUpIcon,
		FilterIcon,
		RadioIcon,
		UserXIcon,
		QuestionIcon
	];

	for (const icon of icons)
		Vue.component(icon.name, icon);
}

export default { install };
